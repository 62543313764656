<template lang="pug">
    section#galeriaNav
        .wrapper
            .controls(v-if="contents.content.length > 1")
                button.left(@click="changeImage(ativo - 1)")
                    SvgIcon(data="@svgs/seta-ippuc.svg")
                button.right(@click="changeImage(ativo + 1)")
                    SvgIcon(data="@svgs/seta-ippuc.svg")
            ul.items
                li(v-for="image, index in contents.content", :class="{'ativo': ativo == index}", @click="showGaleria(contents.content, index)").item
                    Imagem(:contents="image")
            ul.nav-items(v-if="contents.content.length > 1")
                li(v-for="image, index in contents.content" @click="changeImage(index)", :class="{'ativo': ativo == index}" :style="style").nav-item
                    Imagem(:contents="image")
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'component-galeria-nav',
  components,
  props,
  methods: {
    changeImage (index) {
      if (index >= this.contents.content.length) {
        this.ativo = 0
      } else if (index < 0) {
        this.ativo = this.contents.content.length - 1
      } else {
        this.ativo = index
      }
      if (this.ativo <= this.contents.content.length - 4) {
        const gap = parseFloat(window.getComputedStyle(document.querySelector('.nav-items')).gap)
        const move = (document.querySelector('.nav-item').offsetWidth + gap) * this.ativo
        this.style.transform = `translateX(-${move}px)`
      } else if (this.ativo == this.contents.content.length - 1) {
        const gap = parseFloat(window.getComputedStyle(document.querySelector('.nav-items')).gap)
        const move = (document.querySelector('.nav-item').offsetWidth + gap) * (this.ativo - 3)
        this.style.transform = `translateX(-${move}px)`
      }
    },
    showGaleria (contents, index) {
      this.$store.commit('setAsideGaleria', {
        index,
        contents
      })
    }
  },
  data () {
    return {
      ativo: 0,
      style: {
        transform: 'translateX(0)'
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./GaleriaNav.styl"></style>
